/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";

export const onClientEntry = () => {
    if (typeof window !== "undefined") {
      const script = document.createElement("script");
      script.src = "https://js.dispenseapp.com/v1";
      script.async = true;
      document.body.appendChild(script);
    }
  };